// Spacing scale - margins & padding

export const spacingScale = {
    spacing_xxs:"2px", // 2px spacing
    spacing_xs:"4px", // 4px spacing
    spacing_s:"8px",// 8px spacing
    spacing_m: "1rem", // $base-space: 1rem/16px spacing
    spacing_l: "32px", // 32px spacing
    spacing_xl:"64px",  // 64px spacing
    spacing_xxl:"128px" // 128px spacing 
}