export const Blue = {
    100: "#8dd9f4",
    200: "#00acee",
    300: "#030086",
    400: "#5f25a4",
    500: "#050449"
  };
  
  export const Green = {
    100: "#bcddcb",
    200: "#367b48",
    300: "#276738"
  };
  
  export const Yellow = {
    100: "#ffff00",
    200: "#cab23f",
    300: "#b49e35"
  };
  
  export const Red = {
    100: "#ff00ff",
    200: "#FACECE",
    300: "#f70c0c"
  };
  
  export const Neutral = {
    100: "#ffffff",
    200: "#f4f5f7",
    300: "#d3d3d3;",
    400: "#a4a4a4",
    500: "#515151",
    600: "#3c3c3c",
    700: "#212121"
  };

 